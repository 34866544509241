// src/app/services/version.service.ts

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  constructor() {}

  getVersion(): string {
    return 'HRMS v1.0.0.0.0 (52.01.05.72)';
  }
  getDate(): string {
    return 'Jan 27, 2025';
  }
}
